import React, { useState } from 'react'
import { useSections } from '../../hooks/useSections'

const SaveAsTemplateForm = ({ section }) => {
  const [showForm, setShowForm] = useState(false)
  const [name, setName] = useState('')
  const { saveSectionAsTemplate, isLoading } = useSections()

  const saveTemplate = async () => {
    await saveSectionAsTemplate({ section, name })
    setShowForm(false)
  }

  return (
    <div className="mt-16">
      {isLoading && <div>Loading...</div>}
      {!showForm && (
        <button onClick={() => setShowForm(true)}>Save as template</button>
      )}
      {showForm && (
        <div>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
      
          <button onClick={saveTemplate}>Save As Template</button>
        </div>
      )}
    </div>
  )
}

export default SaveAsTemplateForm
