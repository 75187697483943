import React from 'react'
import { useUsers } from '../hooks/useUsers'
import { useSections } from '../hooks/useSections'
import Section from './sections/Section'
import UserInfo from './users/UserInfo'
import NewUserForm from './users/NewUserForm'

const MainPanel = () => {
  const { currentUser, createNewUserMode } = useUsers()
  const { section } = useSections();

  if (!currentUser) {
    return <div />
  }

  if (createNewUserMode) {
    return <NewUserForm />
  }

  return (
    <div className="p-8">
      <h1 className="text-bold text-3xl">
        {`${currentUser.id} (${currentUser.name})`}
      </h1>

      {!section && <UserInfo />}

      <Section />
    </div>
  )
}

export default MainPanel
