import React from 'react'
import { useSections } from '../hooks/useSections'
import { useUsers } from '../hooks/useUsers'

const Siderbar = () => {
  const { sections, section, setSection, isLoading } = useSections()
  const { currentUser } = useUsers()

  const onSelectSection = (s) => {
    setSection(s)
  }

  return (
    <div className="w-72 border">
      {isLoading && <div>Loading</div>}

      {currentUser && !isLoading && (
        <div
          onClick={() => onSelectSection(undefined)}
          className={`bg-gray-200 border border-gray-300 p-4 hover:cursor-pointer ${!section && '!border-orange-500'}`}
        >
          Usuario
        </div>
      )}

      {sections.map((s) => (
        <div
          onClick={() => onSelectSection(s)}
          key={s.name}
          className={`${s.color} border border-gray-300 p-4 hover:cursor-pointer ${s.name === section?.name && '!border-orange-500'}`}
        >
          {s.name}
          {s.isEmpty && (
            <div className="float-right w-6 h-6 bg-yellow-300 rounded-full pl-2.5 text-red-500">
              !
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Siderbar
