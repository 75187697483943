import React, { useState, useContext, useEffect, createContext } from 'react'
import { Timestamp } from 'firebase/firestore'
import { useFirebase } from './useFirebase'
import { emptySections } from './emptySections'

export const usersContext = createContext()

export const ProvideUsers = ({ children }) => {
  const [users, setUsers] = useState([])
  const [currentUser, setCurrentUser] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { loadUsers, save } = useFirebase()
  const [createNewUserMode, setCreateNewUserMode] = useState(false)

  const load = async () => {
    setIsLoading(true)

    const u = await loadUsers()
    setUsers(u)
    setIsLoading(false)
  }

  useEffect(() => {
    setTimeout(() => load(), 300)
  }, [])

  const createUser = async (user) => {
    setIsLoading(true)
    user.createdAt = Timestamp.now()
    await save(user, 'users', user.id)
    emptySections.map(async (section) => {
      await save(section, 'users', user.id, 'sections', section.id)
    })
    setIsLoading(false)
  }

  const value = {
    users,
    isLoading,
    currentUser,
    setCurrentUser,
    createNewUserMode,
    setCreateNewUserMode,
    createUser,
  }

  return <usersContext.Provider value={value}>{children}</usersContext.Provider>
}

export const useUsers = () => useContext(usersContext)