import React, { useState, createContext, useContext, useEffect } from 'react';
import { useFirebase } from './useFirebase';
import { useUsers } from './useUsers';

export const productsContext = createContext();

export const ProvideProducts = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { loadCollection } = useFirebase();
  const { currentUser } = useUsers();

  const loadProducts = async () => {
    setIsLoading(true);

    const p = await loadCollection('products')
    setProducts(p)

    const categories = []
    p.forEach((product) => {
      categories[product.category] = product
    })
    setProductsByCategory(categories)

    setIsLoading(false)
  };

  useEffect(() => {
    if (currentUser) {
      loadProducts();
    }
  }, [currentUser]);

  const value = {
    products,
    productsByCategory,
    isLoading,
  }

  return <productsContext.Provider value={value}>{children}</productsContext.Provider>;
};

export const useProducts = () => useContext(productsContext);
