import _ from "lodash";

export const displayValue = (key, section) => {
  const value = section[key]

  switch (key) {
    case 'color':
      return (<div className={`${value} inline-block`}>{value}</div>)
    case 'answers':
      return displayAnswers(value, section.survey)
    case 'survey':
      return displaySurvey(value)
  
    default:
      if (_.isObject(value)) {
        return '[[[Object]]]'
      }
      if (_.isBoolean(value)) {
        return value ? 'SI' : 'NO'
      }

      return value;
  }
}

export const displayAnswers = (answers, survey) => {
  const findQuestion = (id) => {
    let question = id
    _.map(survey.cards, (value, key) => {
      _.map(value.questions, (v, k) => {
        if (id === v.id) {
          question = v.label
        }
      })
    })
    return question
  }
  return (
    <div className="border inline-block">
      {_.map(answers, (value, key) => (
        <div className="border-b p-4 max-w-lg">
          <div className="font-bold mb-2">{findQuestion(key)}</div>
          <span>
            {_.isArray(value) ? value.join(', ') : value}
          </span>
        </div>
      ))}
    </div>
  )
}

export const displaySurvey = (survey) => {
  return (
    <div>
      {survey.cards.map(card => (
        <div className="border p-4">
          <strong className="text-lg">{card.title}</strong> (<i>{card.type}</i>)
          <hr />
          <div className="border p-2">
            {card.questions?.map(q => (
              <div>
                * {q.id}: <strong>{q.label}</strong> ({q.type})
                {q.subtitle && <div className="ml-6 text-sm text-gray-700">{q.subtitle}</div>}
                {q.options && (
                  <div className="ml-6">[{q.options.join(', ')}]</div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}