import React from 'react'
import { useUsers } from '../hooks/useUsers'
import { useSections } from '../hooks/useSections'


const Siderbar = () => {
  const { users, setCurrentUser, isLoading, setCreateNewUserMode } = useUsers()
  const { setSection, setSections } = useSections()


  const onSelectUser = (user) => {
    setCurrentUser(user)
    setSection(undefined)
    setCreateNewUserMode(false)
  }

  const createNewUser = () => {
    setCreateNewUserMode(true)
    setSections(undefined)
  }

  return (
    <div className="w-72 border">
      <h3 className="font-bold text-center p-4 border-b">Usuarios</h3>

      <div onClick={createNewUser} className="border-b border-gray-300 p-4 hover:bg-gray-50 hover:cursor-pointer">
        + Crear Nuevo Usuario
      </div>

      {isLoading && <div>Loading</div>}

      {users.map((user) => (
        <div onClick={() => onSelectUser(user)} key={user.id} className="border-b border-gray-300 p-4 hover:bg-gray-50 hover:cursor-pointer">
          {`${user.id} (${user.name})`}
        </div>
      ))}
    </div>
  )
}

export default Siderbar
