import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useSections } from '../../hooks/useSections'
import { displayValue } from './helper'
import SaveAsTemplateForm from './SaveAsTemplateForm'

const Section = () => {
  const { section, templates, loadTemplates, useSectionTemplate } = useSections();
  const [ template, setTemplate] =  useState(section)

  useEffect(() => {
    if (section?.isEmpty) {
      loadTemplates(section.id)
    }
  }, [section])

  const getSection = () => {
    if (template) {
      return template
    }
    return section
  }

  const useTemplate = async () => {
    await useSectionTemplate(template)
    setTemplate(undefined)
  }

  return (
    <div className="flex flex-row">
      {section?.isEmpty && (
        <div className="w-48 mt-8 mr-8">
          <div className="font-bold">Templates:</div>
          {templates?.map((t) => (
            <div
              onClick={() => setTemplate(t)}
              className={`p-4 border bg-gray-200 hover:bg-gray-300 border-gray-400 cursor-pointer ${t.templateName === template?.templateName && '!border-red-500'}`}
            >
              {t.templateName}
            </div>
          ))}
        </div>
      )}
      {getSection() && (
        <div className="flex-1">
          {section?.isEmpty && template && (
            <button onClick={useTemplate}>Use this template</button>
          )}

          {_.map(getSection(), (v, key) => (
            <div className="py-3 border-b" key={key}>
              <span className="font-bold pr-4">{key}:</span>
              <span>{displayValue(key, getSection())}</span>
            </div>
          ))}
          <hr />

          {!section?.isEmpty && (
            <SaveAsTemplateForm section={section} />
          )}

        </div>
      )}
    </div>
  )
}

export default Section
