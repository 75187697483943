import React, { useState, createContext, useContext, useEffect } from 'react';
import { useFirebase } from './useFirebase';
import { useUsers } from './useUsers';

export const sectionsContext = createContext();

export const ProvideSections = ({ children }) => {
  const [sections, setSections] = useState([]);
  const [section, setSection] = useState([]);
  const [templates, setTemplates] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const { userCollectionLive, loadCollection, save } = useFirebase();
  const { currentUser } = useUsers();

  const loadSections = () => {
    setIsLoading(true);

    const loadCallback = (arr) => {
      arr.sort((a, b) => a.position - b.position);
      setSections(arr);
      setIsLoading(false);
    };
    userCollectionLive(currentUser, loadCallback, 'sections');
  };

  useEffect(() => {
    if (currentUser) {
      loadSections();
    }
  }, [currentUser]);

  const saveSectionAsTemplate = async ({ section, name }) => {
    setIsLoading(true)
    section.templateName = name
    await save(section, 'templates', 'sections', section.id, name)
    setIsLoading(false)
  }

  const loadTemplates = async (id) => {
    setIsLoading(true)
    const t = await loadCollection('templates', 'sections', id)
    setTemplates(t)
    setIsLoading(false)
  }

  const useSectionTemplate = async (template) => {
    setIsLoading(true)
    // if (template.id) {
    //   delete template.id
    // }
    await save(template, 'users', currentUser.id, 'sections', template.templateId)
    setIsLoading(false)
  }

  const value = {
    sections,
    section,
    setSection,
    isLoading,
    saveSectionAsTemplate,
    templates,
    loadTemplates,
    useSectionTemplate
  };

  return <sectionsContext.Provider value={value}>{children}</sectionsContext.Provider>;
};

export const useSections = () => useContext(sectionsContext);
