import React, { useState } from 'react'
import { useUsers } from '../../hooks/useUsers'

const NewUserForm = () => {
  const { isLoading, createUser } = useUsers()
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [welcome, setWelcome] = useState('Bienvenida a tu programa personalizado de SKINCARE.')

  const create = async () => {
    await createUser({ id, name, welcome })
    window.location.reload()
  }
  
  return (
    <div className="ml-8 mt-16">
      <div className="font-bold text-red-500">
        Asegurate que no exista otro usuario con el mismo ID para evitar sobreescribir su informacionn
      </div>
      <div className="mt-4">
        <label className="w-24 inline-block">ID:</label>
        <input className="w-48" type="text" value={id} onChange={(e) => setId(e.target.value)} />
      </div>
      <div className="mt-4">
        <label className="w-24 inline-block">Name:</label>
        <input className="w-48" type="text" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="mt-4">
        <label className="w-24 inline-block">
          Welcome:
        </label>
        <textarea className="border w-57 h-32" onChange={(e) => setWelcome(e.target.value)}>{welcome}</textarea>
      </div>
      <div className="mt-8">
        {isLoading ? (<span>Loading...</span>)
          : (<button onClick={create}>Crear Nuevo Usuario</button>)
        }
      </div>
    </div>
  )
}

export default NewUserForm
