import './App.css';
import { ProvideUsers } from './hooks/useUsers';
import { ProvideFirebase } from './hooks/useFirebase';
import { ProvideSections } from './hooks/useSections';
import { ProvideProducts } from './hooks/useProducts';
import Siderbar from './components/Siderbar';
import MainPanel from './components/MainPanel';
import SectionsPanel from './components/SectionsPanel';

function App() {
  return (
    <ProvideFirebase>
      <ProvideUsers>
        <ProvideSections>
          <ProvideProducts>
            <div className="flex flex-row">
              <Siderbar />
              <SectionsPanel />
              <MainPanel />

              {/* Hack to force generations of classes that are added in runtime */}
              <span className="w-0 h-0 hidden bg-indigo-500 bg-purple-500 bg-teal-500 bg-fuchsia-500 bg-sky-500" />
            </div>
          </ProvideProducts>
        </ProvideSections>
      </ProvideUsers>
    </ProvideFirebase>
    
  );
}

export default App;
