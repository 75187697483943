import React from 'react'
import { useUsers } from '../../hooks/useUsers'

const UserInfo = () => {
  const { currentUser } = useUsers()

  if (!currentUser) {
    return <div />
  }

  const parseDate = (date) => {
    if (!date) {
      return '-'
    }
    return date.toDate().toDateString()
  }

  return (
    <div>
      <div className="mt-8">
        <label className="font-bold mr-4">ID:</label>
        <span>{currentUser.id}</span>
      </div>
      <div className="mt-8">
        <label className="font-bold mr-4">Name:</label>
        <span>{currentUser.name}</span>
      </div>
      <div className="mt-8">
        <label className="font-bold mr-4">Welcome:</label>
        <span>{currentUser.welcome}</span>
      </div>
      <div className="mt-8">
        <label className="font-bold mr-4">Last Login:</label>
        <span>{parseDate(currentUser.lastLogin)}</span>
      </div>
      <div className="mt-8">
        <label className="font-bold mr-4">Created At:</label>
        <span>{parseDate(currentUser.createdAt)}</span>
      </div>
    </div>
  )
}

export default UserInfo
