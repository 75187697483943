import React, { useRef, createContext, useEffect, useContext } from 'react';
import { initializeApp, setLogLevel } from 'firebase/app';
import { getFirestore, doc, getDoc, getDocs, collection, setDoc, onSnapshot } from 'firebase/firestore';

const clientCredentials = {
  apiKey: 'AIzaSyAzioqDYxNHligt1wOTYZRVflUWVkaQA8A',
  authDomain: 'next-dev-cdd2a.firebaseapp.com',
  projectId: 'next-dev-cdd2a',
  storageBucket: 'next-dev-cdd2a.appspot.com',
  messagingSenderId: '1067565196621',
  appId: '1:1067565196621:web:73083fd159152424f08304',
  measurementId: 'G-2PSXYS85BQ',
};

const FirebaseContext = createContext();

export const ProvideFirebase = ({ children }) => {
  const firebaseApp = useRef()
  const db = useRef()

  useEffect(() => {
    if (!firebaseApp.current) {
      const app = initializeApp(clientCredentials)
      // Analytics
      // if ('measurementId' in clientCredentials) firebase.analytics();
      firebaseApp.current = app
      
      db.current = getFirestore(app)
    }
  }, []);

  const loadUsers = async (callback) => {
    const arr = [];
    const querySnapshot = await getDocs(collection(db.current, 'teams', 'realpeople', 'users'));

    querySnapshot.forEach((d) => {
      arr.push({ ...d.data(), id: d.id });
    });

    return arr;
  }

  const loadCollection = async (...path) => {
    const arr = [];
    const querySnapshot = await getDocs(collection(db.current, 'teams', 'realpeople', ...path));

    querySnapshot.forEach((d) => {
      arr.push({ ...d.data(), id: d.id });
    });

    return arr;
  }

  const collectionLive = (callback, ...path) => {
    const c = collection(db.current, 'teams', 'realpeople', ...path);
    const unsubscribe = onSnapshot(c, (querySnapshot) => {
      const arr = [];
      querySnapshot.forEach((d) => arr.push({ ...d.data(), id: d.id }));
      callback(arr);
    });

    return unsubscribe;
  };

  const userCollectionLive = (user, callback, ...path) => collectionLive(callback, 'users', user.id, ...path);

  const save = async (data, ...path) => {
    await setDoc(doc(db.current, 'teams', 'realpeople', ...path), data, { merge: true });
  };

  const value = {
    loadUsers,
    loadCollection,
    collectionLive,
    userCollectionLive,
    save,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);
