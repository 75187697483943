export const emptySections = [
  {
    id: 'Anamnesis',
    name: 'Anamnesis',
    short: 'Evaluación y diagnóstico de tu piel.',
    color: 'bg-fuchsia-500',
    position: 1,
    type: 'survey',
    isEmpty: true,
  },
  {
    id: 'Rutinas',
    name: 'Tus Rutinas',
    short: 'Tus rutinas creadas exclusivamente para ti!',
    color: 'bg-sky-500',
    position: 2,
    type: 'routines',
    isEmpty: true,
  },
  {
    id: 'Training',
    name: 'Skincare Training',
    short: 'Videos del paso a paso de una correcta aplicación de tus productos.',
    color: 'bg-indigo-500',
    position: 3,
    type: 'trainings',
    isEmpty: true,
  },
  {
    id: 'Lifestyle',
    name: 'Lifestyle',
    short: 'Tus guias para una piel saludable.',
    color: 'bg-purple-500',
    position: 4,
    type: 'resources',
    isEmpty: true,
  },
]
